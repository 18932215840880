import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

const app = createApp(App);
app.use(VueAxios, axios);

const routes = [
  {
    path: '/:user',
    name: 'App',
    component: App,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

app.use(router);

app.mount('#app');
