
<template>
   <!-- Check if the user accessed the homepage -->
   <div v-if="isHomepage" class="homepage-container">
      <div class="logo-image">
        <img src="./assets/rh-white-anim2.gif" alt="logo" class="homepage-logo">
      </div>
      <div class="homepage-link">
        <a href="https://www.rockhall.com" target="_blank">www.rockhall.com</a>
      </div>
    </div>
    <!-- If it's not the homepage, show the regular card -->
    <div v-else class="card" ref="cardToDownload">
    <div class="card-header">

      <div class="profile-image">
        <img v-if="person.profilePictureUrl" :src="person.profilePictureUrl" alt="profile">
  <img v-else src="./assets/user.png" alt="profile"> <!-- Default image if profilePictureUrl is not available -->

      </div>
      

      <div class="profile-info">
        <h3>{{ person.givenName }} {{ person.familyName1 }}</h3>
        <p>{{ person.pronoun }}</p>
        <p>{{ person.jobTitle }} / {{ person.department }}</p>
        <h6>{{ person.emailUri }}</h6>
        <h6>{{ person.formattedNumber }}</h6>
      </div>
    <div class="card-footer">
      <a id="addToContacts" href="#" ref="addToContacts">ADD TO CONTACTS</a>
    </div>
    <div class="logo-image">
      <img src="./assets/rh-white-anim2.gif" alt="logo">
    </div>
  </div>
  </div>
</template>
<script>

import axios from 'axios';




export default {
  name: 'App',
  data() {
    return {
      person: {
        givenName: '',
        familyName1: '',
        pronoun: '',
        jobTitle: '',
        department: '',
        emailUri: '',
        formattedNumber: '',
        profilePictureUrl: '',
      }
    };
  },
  async created() {

    // Determine if it's the homepage based on the URL
    this.isHomepage = window.location.pathname === '/';

  },
  mounted() {
    if (!this.isHomepage) {
      this.fetchUserData();
    }
  },
  methods: {
    fetchUserData() {
      // Get the value of a path parameter from the current URL
      const path = window.location.pathname;
      const parts = path.split('/');
      const parameterValue = parts[parts.length - 1];
      console.log(parameterValue);
      const apiUrl = `${process.env.VUE_APP_API_URL}?user=${parameterValue}`;
      axios.get(apiUrl)
        .then(response => {
          console.log(response.data); // Log the received data
          this.person = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },

    // ... Other code remains the same ...
    generateVCard() {
      const vCardData = [
      "BEGIN:VCARD",
        "VERSION:3.0",
        "FN:" + this.person.givenName + " " + this.person.familyName1,
        "N:" + this.person.familyName1 + ";" + this.person.givenName + ";;;",
        "ORG:Rock & Roll Hall of Fame",
        "TITLE:" + this.person.jobTitle ?? '',
        "TEL:" + this.person.formattedNumber ?? '',
        "EMAIL:" + this.person.emailUri ?? '',
        "DEPARTMENT:" + this.person.department ?? '',
        "END:VCARD"
      ].join("\n");
      return vCardData;
    },

    addToContacts(ref) {
      const vCardData = this.generateVCard();
      const blob = new Blob([vCardData], { type: 'text/vcard' });
      const vCardURL = URL.createObjectURL(blob);
      ref.href = vCardURL;

      // Show a message instructing the user to add the contact manually
      //   const message = 'Tap the link below or copy the vCard data to your contacts.';
      //   if (window.confirm(message)) {
      //     // Open the vCard in a new tab/window to prompt the user to add it to contacts manually
      //     navigator.msSaveOrOpenBlob(blob, 'contact.vcf');
      //     const newTab = window.open(vCardURL, '_blank');
      //     if (!newTab) {
      //       // Blocked by the browser's popup blocker
      //       alert('Please allow pop-ups to add the contact manually.');
      //     }
      //   }
      // }

    }
  },
  watch: {
    person() {
      this.$nextTick(() => {
        this.addToContacts(this.$refs.addToContacts);
      });
    }
  }
};




/*import axios from 'axios'
export default {
  name: 'App',
  data() {
    return {
      person: {}
    };
  },
  created() {
    const userId = this.$route.params.userId;
    // Replace 'YOUR_API_URL_HERE' with the actual API URL from your Lambda function
    const apiUrl = `https://tcid6qhag8.execute-api.us-east-2.amazonaws.com/default/hello?userId=${userId}`;
    axios.get(apiUrl)
      .then(response => {
        console.log(response.data); // Log the received data
        this.person = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
};
/*import axios from 'axios';
export default {
  name: 'App',
  data() {
    return {
      person: {}
    };
  },
  created() {
    axios.get('/data.json')
      .then(response => {
        this.person = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }
};*/

</script>
<style>
@font-face {
  font-family: 'Sohne';
  src:url('~@/assets/Fonts/Söhne-Kräftig.ttf') format('truetype');}
@font-face {
  font-family: 'Sohne';
  src:url('~@/assets/Fonts/Söhne-Leicht.ttf') format('truetype');
  font-weight: bold;}
   
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #050505;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 540px;
  background-color: #080808;
  border-radius: 0px;
  box-shadow: 0 0px 25px rgb(246, 243, 243);
  padding: 20px;
  border-color: #1a252f;
  border-width: 5px;
  
  
}
@media (max-width:576px){
  .card{
    border-color:none;
    border-width: 0px;
    box-shadow: none;

  }
}



.profile-image {
  width: 170px;
  height: 170px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
  /* Add this line to center the container horizontally */
  display: flex;
  align-items: center;
  /* Add this line to center the image vertically */
  justify-content: center;
  /* Add this line to center the image horizontally */
  border: 1px solid rgba(15, 33, 134, 0.03);
  background-color: gray;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: -25px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: inherit;
}

.profile-info {
  text-align: center;
}

h3 {
  font-size: 33px;
  margin-top: 30px;
  text-align: center;
  font-family: Sohne, Arial, Helvetica, sans-serif;
  font-weight: bold;
  color:#fff;
}

h6 {
  font-size: 22px;
  margin-bottom: 1px;
  margin-top: 20px;
  text-align: center;
  font-family:Sohne, Arial, Helvetica, sans-serif;
  font-style: bold;
  color: rgb(254, 253, 253);
}

h4 {
  font-size: 20px;
  color:#B5BAC2;
  margin-bottom: -10px;
  margin-top: -25px;
  text-align: center;
  font-family: Sohne,Arial, Helvetica, sans-serif;
  font-style: bold;
}

p {
  font-size: 20px;
  color:#B5BAC2;
  font-style: bold;
  font-family: Sohne,Arial, Helvetica, sans-serif;
}

a#addToContacts {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-size: 12px;
  padding: 10px 20px;
  color: #1E6EFF;
  font-size: 25px;
  border: solid;
  border-radius: 0px;
  border-color:#1E6EFF;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  font-family: Sohne,Arial, Helvetica, sans-serif;
}

a#addToContacts:hover {
  background-color: #000407;
}

.logo-image {
  margin-top: 20px;
  text-align: center;
}

.logo-image img {
  width: 160px;
  height: 150px;
  background-color: #000509;
  object-fit: fill;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
}

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.homepage-logo {
  width: 300px; /* Set the desired size for the logo */
  height: auto; /* Automatically adjust the height to maintain the aspect ratio */
}

.homepage-link {
 
  text-align: center;
}

.homepage-link a {
  font-size: 18px; /* Adjust the font size as needed */
  color: #1E6EFF;
  text-decoration: none;
}
</style>
